import { SCHEDULES } from "constants/paths";
import request from "./index";
import { IParseSchedules, IPostSchedule } from "types/schedules";
import { serialize } from "object-to-formdata";

export const parseSchedule = (id: number) => {
  //const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return request<IParseSchedules>({
    options: {
      url: `${SCHEDULES}/${id}/parsed?occurrences_count=2`,
    },
  });
};

export const postSchedule = (data: { schedule: IPostSchedule }) => {
  return request<any>({
    options: {
      url: `${SCHEDULES}`,
      method: "post",
      data: serialize(data),
    },
  });
};
