import { Flex } from "components";
import { H3 } from "components/Typography";
import { AddAvatar } from "./components/AddAvatar";
import { ProfileForm } from "./components/ProfileForm";

export const MyAccountView = () => {
  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <H3 variant="h3">Moje konto</H3>
      <AddAvatar />
      <ProfileForm />
    </Flex>
  );
};
