import { H2 } from "components/Typography";
import { useWindowWidth } from "hooks/useWindowWidth";

interface IHeader {
  text: string;
  withoutMargin?: boolean;
}

export const Header = ({ text, withoutMargin }: IHeader) => {
  const { isMobile } = useWindowWidth();
  return (
    <H2 variant={isMobile ? "h3" : "h2"} mb={withoutMargin ? "" : "20px"}>
      {text}
    </H2>
  );
};
