import { Flex } from "components";
import { H3, H6 } from "components/Typography";
import { VisitsList } from "./components/VisitsList";
import { useTranslation } from "react-i18next";

const VisitsView = () => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <H3 variant="h3">{t("visitsView.yourVisits")}</H3>
      <VisitsList variant="upcoming" />
      <H6 variant="h6" fontWeight={400} color="grey">
        {t("visitsView.endedVisits")}
      </H6>
      <VisitsList variant="historical" />
    </Flex>
  );
};

export default VisitsView;
