import { Flex, Grid } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const StyledToolbar = styled(Grid)`
  width: 100%;
  align-items: center;
  justify-items: stretch;
  text-align: center;
  grid-template-columns: repeat(2, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  } ;
`;

export const NavigationWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  border-right: 1px solid #ddd;
  @media ${device.tablet} {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const ToolbarButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #ddd;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    svg > path {
      stroke: ${({ theme }) => theme.palette.neutral.white};
    }
  }
`;
