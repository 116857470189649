import qs from "qs";
import request from "./index";
import { VISITS } from "constants/paths";
import {
  IPostVisit,
  IGetVisits,
  IGetVisit,
  IPostVisitPayload,
} from "types/visits";
import { serialize } from "object-to-formdata";
interface IGetVisitsFilters {
  archived?: boolean;
  expert_id?: number;
  patient_id?: number;
}

export const getVisits = (filters?: IGetVisitsFilters) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return request<IGetVisits>({
    options: {
      url: `${VISITS}?${query}`,
    },
  });
};

export const postVisits = (data: { visit: IPostVisitPayload }) => {
  return request<IPostVisit>({
    options: {
      method: "post",
      url: `${VISITS}`,
      data: serialize(data),
    },
  });
};
export const getVisit = (id: number | string) => {
  return request<IGetVisit>({
    options: {
      url: `${VISITS}/${id}`,
    },
  });
};
