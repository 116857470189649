import { Flex } from "components/Flex";
import styled, { css } from "styled-components";
import { ReactComponent as SendIcon } from "assets/icons/send-icon.svg";

export const SendIconStyled = styled(SendIcon)`
  cursor: pointer;
  flex-shrink: 0;
`;

export const ChatHeader = styled(Flex)`
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
`;

export const PersonalData = styled(Flex)`
  ${({ theme }) => `
      background-color: ${theme.palette.backgroundGuest};
    `};
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

export const DotOnline = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.green};
  `};
  height: 10px;
  width: 10px;
  border-radius: 10px;
`;

export const Content = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `};
  height: 100%;
  flex-direction: column-reverse;
  align-items: center;
  overflow-y: auto;
  padding: 10px;
`;

export const MessageOwn = css`
  margin-left: auto;
  margin-right: 0px;
  color: white;
`;
export const MessageNotOwn = css`
  margin-right: auto;
  margin-left: 0px;
`;

export const Message = styled(Flex)<{ own?: boolean }>`
  word-break: break-all;
  font-size: 14px;
  font-weight: 600;
  ${({ theme, own }) => `
      background-color: ${
        own ? theme.palette.primary.main : theme.palette.backgroundGuest
      };
      ${own ? MessageOwn : MessageNotOwn};
  `};
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
`;

export const MessageInputWrapper = styled(Flex)`
  ${({ theme }) => `
      background-color: ${theme.palette.neutral.lightGrey};
  `};
  border-radius: 60px;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
`;

export const MessageInput = styled.input`
  padding: 10px 0px 10px 20px;
  width: 100%;
  flex-shrink: 1;
  background-color: transparent;
`;
