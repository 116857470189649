import { EULogos } from "components";
import { Flex } from "components/Flex";
import { H4, P } from "components/Typography";
import {
  contactAddress,
  contactData,
  contactDescription,
  openingHours,
} from "constants/contactInfo";
import { useWindowWidth } from "hooks/useWindowWidth";
import styled from "styled-components";

const Wrapper = styled(Flex)`
  flex-flow: column nowrap;
  width: 100%;
  border-radius: 10px;
  padding: 30px;
  background-color: ${({ theme }) => theme.palette.background};
  gap: 20px;
  align-self: flex-start;
`;

const ContactLink = styled.a`
  all: unset;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`;

const AddressLink = styled.a`
  all: unset;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ContactCard = () => {
  const { isMobile } = useWindowWidth();
  return (
    <Wrapper maxWidth={isMobile ? "" : "400px"}>
      <EULogos showText />
      <H4 variant="h4">O nas</H4>
      <P variant="body">{contactDescription}</P>
      <H4 variant="h4">Kontakt</H4>
      <Flex flexDirection="column" gap="10px">
        <Flex justifyContent="space-between">
          <P variant="body">Telefon:</P>
          <ContactLink href={`tel:${contactData.phone}`}>
            {contactData.phone}
          </ContactLink>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">E-mail:</P>
          <ContactLink href={`mailto:${contactData.email}`}>
            {contactData.email}
          </ContactLink>
        </Flex>
      </Flex>
      <H4 variant="h4">Gdzie nas znajdziesz?</H4>
      <AddressLink
        href={contactAddress.link}
        target="_blank"
        rel="noreferrer noopener"
      >
        {contactAddress.text}
      </AddressLink>
      <Flex flexDirection="column" gap="10px">
        <Flex justifyContent="space-between">
          <P variant="body">Poniedziałek:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.mon}
          </P>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">Wtorek:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.tue}
          </P>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">Środa:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.wen}
          </P>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">Czwartek:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.thu}
          </P>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">Piątek:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.fri}
          </P>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">Sobota:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.sat}
          </P>
        </Flex>
        <Flex justifyContent="space-between">
          <P variant="body">Niedziela:</P>
          <P variant="body" fontWeight={600}>
            {openingHours.sun}
          </P>
        </Flex>
      </Flex>
    </Wrapper>
  );
};
