import { Flex, Header } from "components";
import styled from "styled-components";
import HomeImage from "assets/images/home-view.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as CardIcon1 } from "assets/icons/card-1.svg";
import { ReactComponent as CardIcon2 } from "assets/icons/card-2.svg";
import { ReactComponent as CardIcon3 } from "assets/icons/card-3.svg";
import { ReactComponent as CardIcon4 } from "assets/icons/card-4.svg";
import { HomeCard } from "./components/HomeCard";

const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  height: auto;
`;

export const HomeView = () => {
  const { t } = useTranslation();

  const cards = [
    {
      icon: <CardIcon1 />,
      title: t("homeView.cards.0.title"),
      desc: t("homeView.cards.0.desc"),
    },
    {
      icon: <CardIcon2 />,
      title: t("homeView.cards.1.title"),
      desc: t("homeView.cards.1.desc"),
    },
    {
      icon: <CardIcon3 />,
      title: t("homeView.cards.2.title"),
      desc: t("homeView.cards.2.desc"),
    },
    {
      icon: <CardIcon4 />,
      title: t("homeView.cards.3.title"),
      desc: t("homeView.cards.3.desc"),
    },
  ];

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Header text={t("homeView.podiatryOffice")} />
      <Image src={HomeImage} alt="gabinet photo" />
      {cards.map((e, k) => (
        <HomeCard key={k} card={e}>
          <Flex flexGrow="1">{e.icon}</Flex>
        </HomeCard>
      ))}
    </Flex>
  );
};
