import { Flex } from "components/Flex";
import { useTranslation } from "react-i18next";
import { HourBox, Label } from "./VisitHours.styled";
import { useFormContext } from "react-hook-form";
import { IAddVisit } from "types/visits";
import { useCallback, useEffect, useState } from "react";
import { parseSchedule } from "api/shedules";
import { IParsedSchedule } from "types/schedules";
import moment from "moment";
import { P } from "components/Typography";

const elementDimensions = {
  width: "66px",
  height: "50px",
};

export const VisitHours: React.FC = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<IAddVisit>();
  const date = watch("date");
  const hour = watch("hour");
  const serviceTime = watch("service.data.duration_in_minutes");
  const expert = watch("expert.data");
  const [schedule, setSchedule] = useState<IParsedSchedule[]>();
  const [visitHours, setVisitHours] = useState<string[]>([]);

  const fetchShedule = useCallback(async () => {
    try {
      const response = await parseSchedule(expert.schedule.id);
      if (response.status === 200) {
        setSchedule(response.data.data);
      }
    } catch {}
  }, [expert]);

  useEffect(() => {
    fetchShedule();
  }, [expert, fetchShedule]);

  useEffect(() => {
    if (!expert) {
      setVisitHours([]);
    }
  }, [expert]);

  const handleVisitHours = useCallback(() => {
    if (!schedule) return setVisitHours([]);

    const scheduleForDate = schedule.filter((e) =>
      moment(e.date).isSame(moment(date)),
    );
    if (scheduleForDate && serviceTime && date) {
      let parsedVisitHours: string[] = [];

      scheduleForDate.forEach((e) => {
        let start = moment(e.availability.starts_at, "HH:mm");
        const end = moment(e.availability.ends_at, "HH:mm");

        while (start.isBefore(end)) {
          const tmp = moment(start).add(serviceTime, "m");
          if (tmp.isSameOrBefore(end)) {
            parsedVisitHours.push(start.format("HH:mm"));
          }
          start = moment(start).add(serviceTime, "m");
        }
        console.log(parsedVisitHours);
      });
      return setVisitHours(parsedVisitHours);
    } else {
      return setVisitHours([]);
    }
  }, [schedule, serviceTime, date]);

  useEffect(() => {
    handleVisitHours();
  }, [handleVisitHours]);

  useEffect(() => console.log(hour), [hour]);

  return (
    <Flex flexDirection={"row"} alignItems={"baseline"}>
      <Flex>
        <Label
          variant="body2"
          fontWeight={600}
          height={elementDimensions.height}
        >
          {t("visitsView.visitHour")}:
        </Label>
      </Flex>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        width="100%"
        gap="8px"
        alignItems={"center"}
      >
        {!!visitHours.length ? (
          visitHours.map((mapHour) => (
            <HourBox
              key={mapHour}
              isActive={moment(mapHour, "HH:mm").isSame(moment(hour, "HH:mm"))}
              width={elementDimensions.width}
              height={elementDimensions.height}
              onClick={() => {
                setValue("hour", mapHour);
              }}
            >
              {mapHour}
            </HourBox>
          ))
        ) : (
          <P variant="body2" color="grey">
            Brak dostępnych godzin tego dnia
          </P>
        )}
      </Flex>
    </Flex>
  );
};
