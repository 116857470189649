import styled, { css, CSSProperties } from "styled-components";
import {
  grid,
  GridProps,
  gridArea,
  GridAreaProps,
  gridColumnGap,
  GridColumnGapProps,
  gridRow,
  GridRowProps,
  gridColumn,
  GridColumnProps,
  gridRowGap,
  GridRowGapProps,
  gridGap,
  GridGapProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  gridTemplateAreas,
  GridTemplateAreasProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridAutoColumns,
  GridAutoColumnsProps,
  gridAutoFlow,
  GridAutoFlowProps,
  gridAutoRows,
  GridAutoRowsProps,
  space,
  SpaceProps,
  layout,
  LayoutProps
} from "styled-system";

interface IGrid
  extends SpaceProps,
    GridProps,
    GridAreaProps,
    GridColumnGapProps,
    GridRowProps,
    GridColumnProps,
    GridRowGapProps,
    GridGapProps,
    GridTemplateRowsProps,
    GridTemplateAreasProps,
    GridTemplateColumnsProps,
    GridAutoColumnsProps,
    GridAutoFlowProps,
    GridAutoRowsProps,
    LayoutProps {
  gap?: CSSProperties["gap"];
}

export const Grid = styled.div<IGrid>(
  ({ gap }) =>
    css`
      display: grid;
      ${gap ? `gap: ${gap};` : ""}
    `,
  space,
  grid,
  gridArea,
  gridRow,
  gridColumn,
  gridRowGap,
  gridGap,
  gridTemplateRows,
  gridTemplateAreas,
  gridTemplateColumns,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumnGap,
  layout
);
