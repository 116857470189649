import { Flex } from "components/Flex";
import styled from "styled-components";
import { ReactComponent as Back } from "assets/icons/back-icon.svg";
import { useNavigate } from "react-router-dom";
import { P } from "components/Typography";

const Wrapper = styled(Flex)`
  cursor: pointer;
  gap: 10px;
  align-items: center;
`;

const Circle = styled(Flex)`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.palette.backgroundGuest};
  justify-content: center;
  align-items: center;
`;

interface IBackButtonProps {
  text: string;
  onClick?: () => void;
}

export const BackButton = ({ text, onClick }: IBackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!!onClick) onClick();
    else navigate(-1);
  };

  return (
    <Wrapper onClick={handleClick}>
      <Circle>
        <Back />
      </Circle>
      <P variant="body">{text}</P>
    </Wrapper>
  );
};
