export const navItems = [
  {
    label: "Usługi",
    path: "/services",
    role: ["Patient"],
  },
  {
    label: "Specjaliści",
    path: "/specialists",
    role: ["Patient"],
  },
  {
    label: "Dostępność",
    path: "/schedule",
    role: ["Expert"],
  },
  {
    label: "Baza wiedzy",
    path: "/knowledge-base",
    role: ["Patient", "Expert"],
  },
];
