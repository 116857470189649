import { SERVICES } from "constants/paths";
import { IGetServices, IServices } from "types/services";
import request from "./index";
import qs from "qs";

export const getServices = (filters?: IGetServices) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return request<IServices>({
    options: {
      url: `${SERVICES}?${query}`,
    },
  });
};
