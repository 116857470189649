import React, { createContext, useEffect, useState } from "react";
import { IUser, IUserType } from "types/users";

interface IUserContext {
  userData: TUserData;
  userRole: IUserType;
  updateData: (user: IUser) => void;
}

type TUserData = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  avatar: string | Blob;
  phone: string;
};

const defaultValues: IUserContext = {
  updateData: () => {},
  userData: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    avatar: "",
    phone: "",
  },
  userRole: "None",
};

export const UserContext = createContext<IUserContext>(defaultValues);

interface IProvider {
  children: React.ReactNode;
}

const UserProvider = ({ children }: IProvider) => {
  const [userData, setUserData] = useState<TUserData>(defaultValues.userData);
  const [userRole, setUserRole] = useState<IUserType>(defaultValues.userRole);
  const currentUser = localStorage.getItem("currentUser");

  useEffect(() => {
    if (currentUser) {
      let data = JSON.parse(currentUser!);
      setUserData({
        id: data.id,
        email: data.email,
        firstName: data.profile?.first_name,
        lastName: data.profile?.last_name,
        avatar: data.profile?.avatar,
        phone: data.profile?.phone_number,
      });
      setUserRole(data.type);
    } else {
      setUserData(defaultValues.userData);
      setUserRole(defaultValues.userRole);
    }
  }, []);

  const updateData = (user: IUser) => {
    setUserData({
      id: user.id,
      email: user.email,
      firstName: user.profile.first_name ?? "",
      lastName: user.profile.last_name ?? "",
      avatar: user.profile.avatar ?? "",
      phone: user.profile.phone_number ?? "",
    });
    setUserRole(user.type);
    localStorage.setItem("currentUser", JSON.stringify(user));
  };

  return (
    <UserContext.Provider value={{ userData, userRole, updateData }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
