import styled from "styled-components";

export const ErrorText = styled.div<{ show?: boolean }>`
  ${({ theme, show }) => `
  position:absolute;
  bottom:2px;
  right: 10px;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: ${show ? theme.palette.accent.red : "unset"};
  `};
`;
