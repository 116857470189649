import { Flex } from "components";
import { P } from "components/Typography";
import { format } from "date-fns";
import styled from "styled-components";
import { IVisit, TKind } from "../../../types/visits";
import { Button } from "../../../components/_form";
import { ReactComponent as GoogleMeetIcon } from "assets/icons/google-meet.svg";
import { device } from "../../../styles/theme";
import { getUserFullName } from "utilities/user";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IAppointmentCard {
  visit: IVisit;
}

const StyledBox = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  background: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 10px;
  width: 100%;
  height: 100px;
  gap: 10px;

  @media ${device.laptop} {
    flex-direction: column;
    height: unset;
  }
`;

const DateBox = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  margin: auto;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 10px 20px;
  min-width: 150px;

  @media ${device.laptop} {
    border: unset;
    margin: unset;
    //min-width: unset;
    align-self: flex-start;
    align-items: flex-start;
  }
`;

const Content = styled(Flex)`
  padding: 0 20px;

  div[data-buttons-wrapper="true"] {
    min-width: 166px;
    margin-left: 8px;
  }

  @media ${device.laptop} {
    > ${Flex} {
      border-top: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
      padding-top: 10px;
      flex-direction: column;

      > ${Flex}:first-child {
        align-items: flex-start;

        > ${P} {
          margin: 4px 0;
        }
      }
    }

    div[data-buttons-wrapper="true"] {
      margin: 12px 0;
      flex-direction: row;
    }
  }
`;

const OnlineIconWrapper = styled(Flex)`
  background: ${({ theme }) => theme.palette.backgroundGuest};
  border-radius: 25px;
  padding: 4px 8px;
  margin-left: 8px;
  cursor: pointer;

  > svg {
    width: 16px;
  }
`;

const kindMap = (kind: TKind) => {
  const tmp = {
    telephone: "Teleporada",
    online: (
      <Flex justifyContent="center" alignItems="center">
        Online:
        <OnlineIconWrapper>
          <GoogleMeetIcon />
          <P variant="body" ml={2}>
            Dołącz do spotkania
          </P>
        </OnlineIconWrapper>
      </Flex>
    ),
    meet: "Stacjonarnie",
  };
  return tmp[kind];
};

export const AppointmentCard = ({ visit }: IAppointmentCard) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const startDate = new Date(visit.start_at);

  return (
    <StyledBox>
      <DateBox>
        <P variant="body" color="primary" fontWeight={400}>
          {format(startDate, "dd LLLL yyyy")}
        </P>
        <P variant="h4" fontWeight={700} color="primary">
          {format(startDate, "HH:mm")}
        </P>
      </DateBox>
      <Content flexDirection="column" width="100%" justifyContent="center">
        <Flex justifyContent="space-between" height="100%">
          <Flex flexDirection="column" justifyContent="space-around">
            <P variant="body" color="primary" fontWeight={700}>
              {t("visitsView.service")}: {visit.service.title}
            </P>
            <P variant="body" color="coloured">
              {t("visitsView.expert")}: {getUserFullName(visit.expert.profile)}
            </P>
            <P variant="body2" color="grey">
              {kindMap(visit.kind ?? "online")}
            </P>
          </Flex>
          <Flex
            data-buttons-wrapper
            flexDirection="column"
            justifyContent="space-around"
          >
            <Button
              size="sm"
              onClick={() =>
                navigate(
                  `/visits/add/service/${visit.service.id}/specialist/${visit.expert.id}`,
                )
              }
            >
              {t("buttons.sheduleAnAppointmentAgain")}
            </Button>
            <Button
              outlined
              size="sm"
              onClick={() => navigate(`/visits/${visit.id}`)}
            >
              {t("buttons.visitDetails")}
            </Button>
          </Flex>
        </Flex>
      </Content>
    </StyledBox>
  );
};
