import {
  Components,
  EventPropGetter,
  EventProps,
  ResourceHeaderProps,
  ToolbarProps,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  HeaderDayName,
  HeaderDayNumber,
  StyledCalendar,
} from "./Calendar.styled";
import { MyEventType } from "types/calendar";
import { Flex } from "components/Flex";
import "moment/locale/en-gb";
import "moment/locale/pl";
import { CalendarProps } from "react-big-calendar";
import moment from "moment";
import { theme } from "styles";
import { P } from "components/Typography";

const localizer = momentLocalizer(moment);

interface IMyCalendar extends Omit<CalendarProps, "localizer"> {
  toolbarContent: React.ComponentType<ToolbarProps> | undefined;
}

export interface IEvent {
  id: number;
  fullName: string;
  start: Date;
  end: Date;
  serviceType: string;
  resourceId: number;
}

export interface IResource {
  name: string;
  resourceId: number;
}

export const MyCalendar = (props: IMyCalendar) => {
  const MyEvent = (props: EventProps<IEvent>) => {
    const { event } = props;
    return (
      <div>
        <div>{event.fullName}</div>
        <div>{event.serviceType}</div>
      </div>
    );
  };

  const eventStyleGetter: EventPropGetter<MyEventType> = ({ end }) => {
    if (moment(end).isBefore(moment())) {
      return {
        className: "past-event",
        style: {
          backgroundColor: theme.palette.neutral.lightGrey,
          border: "none",
        },
      };
    }
    return {
      className: "",
      style: {},
    };
  };

  const resourceHeader = (props: ResourceHeaderProps<IResource>) => {
    const { resource } = props;
    return (
      <Flex alignItems="center">
        <P variant="body" color="primary">
          {resource.name}
        </P>
      </Flex>
    );
  };

  const components: Components<MyEventType> = {
    toolbar: props.toolbarContent,
    day: {
      event: MyEvent as (props: EventProps<object>) => JSX.Element,
    },
    week: {
      header: ({ date }) => (
        <Flex flexDirection="column">
          <HeaderDayName>{moment(date).format("dd")}</HeaderDayName>
          <HeaderDayNumber>{moment(date).format("DD")}</HeaderDayNumber>
        </Flex>
      ),
    },
    resourceHeader: resourceHeader as (
      props: ResourceHeaderProps<object>,
    ) => JSX.Element,
  };

  return (
    <StyledCalendar
      localizer={localizer}
      {...props}
      showMultiDayTimes={false}
      min={new Date(2023, 1, 1, 7)}
      max={new Date(2023, 1, 1, 19)}
      step={30}
      formats={{ timeGutterFormat: "HH:mm" }}
      components={components as Components<object>}
      eventPropGetter={eventStyleGetter as EventPropGetter<object>}
      // @ts-ignore
      resourceIdAccessor="resourceId"
    />
  );
};
