import { getPatient } from "api/patients";
import { Spinner } from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IPatient } from "types/patients";

export const PatientCardView = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patient, setPatient] = useState<IPatient>();
  const { id } = useParams();

  const handlePatient = async () => {
    setIsLoading(true);
    try {
      const response = await getPatient(id);
      if (response.status === 200) {
        setPatient(response.data.data);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handlePatient();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        `Tu powinna być karta pacjenta ${patient?.profile.first_name}`
      )}
    </>
  );
};
