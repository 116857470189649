import React, { useContext, useEffect, useRef, useState } from "react";
import { Flex, Avatar, Box } from "components";
import { ReactComponent as ChatIcon } from "assets/icons/chat-icon.svg";
import { P } from "../Typography/Typography";
import chatWS from "./chatWS";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  ChatHeader,
  Content,
  DotOnline,
  Message,
  MessageInputWrapper,
  PersonalData,
  MessageInput,
  SendIconStyled,
} from "./Chat.styled";
import { UserContext } from "contexts/UserContext";
import { Button } from "components/_form";
import { useWindowWidth } from "hooks/useWindowWidth";
import { ReactComponent as X } from "assets/icons/x-icon.svg";
import { useTranslation } from "react-i18next";

type IMessage = {
  id: number;
  isOwn: boolean;
  message: string;
};

interface IChat {
  patient: IChatUser;
  expert: IChatUser;
  onCancel?: () => void;
}

interface IChatUser {
  id: number;
  name: string;
  avatar?: string | Blob;
}

export const Chat = ({ expert, patient, onCancel }: IChat) => {
  const [consultationId, setConsultationId] = useState<number>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [message, setMessage] = useState("");
  const { userData, userRole } = useContext(UserContext);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useWindowWidth();
  const { t } = useTranslation();

  const scrollToBottom = () => {
    containerRef.current?.scrollTo({ top: containerRef.current.clientHeight });
  };

  const chatIdentifier = JSON.stringify({
    channel: "ConsultationChannel",
    patient_id: patient.id,
    expert_id: expert.id,
  });

  useEffect(() => {
    chatWS.send(
      JSON.stringify({
        command: "subscribe",
        identifier: chatIdentifier,
      }),
    );
  }, []);

  useEffect(() => {
    return () => {
      chatWS.send(
        JSON.stringify({
          command: "unsubscribe",
          identifier: chatIdentifier,
        }),
      );
    };
  }, []);

  const getHistoricalMessages = () => {
    setLoading(true);
    chatWS.send(
      JSON.stringify({
        command: "message",
        identifier: chatIdentifier,
        data: JSON.stringify({
          action: "pull_messages",
          sender_identifier: user?.id,
          consultation_id: consultationId,
          per_page: 10,
          page: page,
        }),
      }),
    );
  };

  const handleSendMessage = () => {
    if (!!message)
      chatWS.send(
        JSON.stringify({
          command: "message",
          identifier: chatIdentifier,
          data: JSON.stringify({
            action: "receive",
            sender_identifier: user?.id,
            content: message,
          }),
        }),
      );
    setMessage("");
  };

  useEffect(() => {
    if (!!consultationId && !!userData) {
      getHistoricalMessages();
    }
  }, [consultationId, userData]);

  chatWS.onmessage = (e) => {
    const data = JSON.parse(e.data);
    if (data.hasOwnProperty("type")) {
      if (data.type === "confirm_subscription") {
        setConsultationId(data.consultation_id);
        return;
      }
    } else {
      const message = data.message;
      if (message.hasOwnProperty("json")) {
        const result = JSON.parse(message.json);
        setTotal(result.pagination.count);
        let array: IMessage[] = [];
        result.data.map((e) => {
          array.push({
            id: e.id,
            message: e.content,
            isOwn: e.sender_id === user?.id,
          });
        });
        let mergeArray = [
          ...messages,
          ...array.filter((e) => !messages.includes(e)),
        ];
        setMessages(
          mergeArray.sort(function (a, b) {
            return b.id - a.id;
          }),
        );
        setPage((prev) => prev + 1);
        setLoading(false);
      } else {
        scrollToBottom();
        setMessages((oldMess) => [
          {
            id: message.id,
            message: message.content,
            isOwn: message.sender_id === userData?.id,
          },
          ...oldMess,
        ]);
      }
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: total > messages.length,
    onLoadMore: getHistoricalMessages,
  });

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <ChatHeader>
        <Flex alignItems="center" gap="10px">
          <ChatIcon />
          <P variant="body" color="coloured">
            {userRole === "Expert"
              ? t("visitsView.chatWithPatient")
              : t("visitsView.chatWithExpert")}
          </P>
        </Flex>
        {isMobile && <Button onClick={onCancel} icon={<X />} />}
      </ChatHeader>
      <PersonalData>
        <Avatar size="small" />
        <Flex flexDirection="column">
          <Flex alignItems="center" gap="5px">
            <DotOnline />
            <P variant="body2">Online</P>
          </Flex>
          <P variant="body" fontWeight={600}>
            {userRole === "Expert" ? patient.name : expert.name}
          </P>
        </Flex>
      </PersonalData>
      <Content ref={containerRef}>
        {messages.length > 0 ? (
          <>
            {messages.map((el) => (
              <Message key={el.id} own={el.isOwn}>
                {el.message}
              </Message>
            ))}
            {(loading || total > messages.length) && (
              <P ref={sentryRef} variant="body2" color="grey" my={2}>
                Loading...
              </P>
            )}
          </>
        ) : !!consultationId ? (
          <P variant="body2" color="grey" my="auto">
            {t("visitsView.writeSomething")}
          </P>
        ) : (
          <P variant="body2" color="grey" my="auto">
            {t("visitsView.chatUnavailable")}
          </P>
        )}
      </Content>
      <Box padding="0px 10px 10px 10px">
        <MessageInputWrapper>
          <MessageInput
            placeholder={t("visitsView.startWriting")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={!consultationId}
          />
          <SendIconStyled onClick={handleSendMessage} />
        </MessageInputWrapper>
      </Box>
    </Flex>
  );
};
