import React from "react";
import { Content, Wrapper } from "./Layout.styled";
import { Footer } from "layouts/components/Footer";
import { Navbar } from "layouts/components/Navbar";
import { ContactCard } from "./components/ContactCard";
import { useWindowWidth } from "hooks/useWindowWidth";
import { Sidebar } from "./components/Sidebar";
import { useAccount } from "hooks/useAccount";
import { MobileNav } from "./components/MobileNav";
import UserProvider from "contexts/UserContext";

interface IAppLayout {
  children: React.ReactNode;
  withInfoCard?: boolean;
}

export const AppLayout = ({ children, withInfoCard }: IAppLayout) => {
  const { isMobile } = useWindowWidth();
  const { isLogged } = useAccount();
  const showSidebar = !isMobile && isLogged;
  const showMobileNav = isMobile && isLogged;
  return (
    <Wrapper>
      <UserProvider>
        <Navbar />
        <Content mb={showMobileNav ? "80px" : ""}>
          {showSidebar && <Sidebar />}
          {children}
          {withInfoCard && <ContactCard />}
        </Content>
        {showMobileNav && <MobileNav />}
        {!showMobileNav && <Footer variant="color" />}
      </UserProvider>
    </Wrapper>
  );
};
