import { Checkbox, TextField } from "components/_form";
import { useTranslation } from "react-i18next";
import { H4, P } from "components/Typography";
import { ElementWrapper } from "../AddVisitView.styled";
import { useFormContext } from "react-hook-form";
import { Flex } from "components";
import { useContext, useEffect } from "react";
import { UserContext } from "contexts/UserContext";
import { IAddVisit } from "types/visits";

export const PersonalData = () => {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const { setValue } = useFormContext<IAddVisit>();

  useEffect(() => {
    setValue("patient_id", userData.id);
    setValue("patientName", userData.firstName);
    setValue("patientSurname", userData.lastName);
  }, [setValue, userData]);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <ElementWrapper>
      <H4 variant="h4">Twoje dane</H4>
      <Flex flexDirection="column">
        <TextField label="E-mail:" disabled value={userData.email} />

        <TextField
          type="phone"
          label="Numer telefonu:"
          disabled
          value={userData.phone}
        />
        <TextField
          label="Imię:"
          {...register("patientName")}
          error={t(errors.name?.message || "")}
          disabled
          required
        />

        <TextField
          label="Nazwisko:"
          {...register("patientSurname")}
          error={t(errors.surname?.message || "")}
          disabled
          required
        />
        <Checkbox />
      </Flex>

      <P variant="body">Dane osoby dla której zamawiasz wizytę</P>
      <Flex flexDirection="column">
        <TextField
          label="Imię:"
          {...register("name")}
          error={t(errors.name?.message || "")}
          required
        />

        <TextField
          label="Nazwisko:"
          {...register("surname")}
          error={t(errors.surname?.message || "")}
          required
        />
      </Flex>
    </ElementWrapper>
  );
};
