import MDEditor from "@uiw/react-md-editor";
import { getEntry } from "api/entries";
import { BackButton, Flex, Line, Spinner } from "components";
import { H2, H6 } from "components/Typography";
import { BLOB_URL } from "constants/paths";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IEntry } from "types/entries";
import DermativaLogo from "assets/images/logo-navbar.png";

const Image = styled.img`
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
`;

const Dermativa = styled(Flex)`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: ${({ theme }) => theme.palette.backgroundGuest};
  background-image: url(${process.env.PUBLIC_URL + DermativaLogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  flex-shrink: 0;
`;

export const ArticleAuthor = (
  <Flex gap="10px" alignItems="center">
    <Dermativa />
    <H6 variant="h6">Zespół Dermativa</H6>
  </Flex>
);

export const ArticleView = () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<IEntry>();
  const navigate = useNavigate();

  const handleEntry = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getEntry(slug);
      if (response.status === 200) {
        setArticle(response.data.data);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }, [slug]);

  useEffect(() => {
    handleEntry();
  }, [handleEntry]);

  return (
    <Flex flexDirection="column" width="100%" gap="40px">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <BackButton text="Wróć do bazy wiedzy" />
          <H2 variant="h2">{article?.title}</H2>
          <Image src={`${BLOB_URL}${article?.thumbnail}`} alt="article image" />
          <MDEditor.Markdown source={article?.content} linkTarget="_blank" />
          <Line />
          {ArticleAuthor}
        </>
      )}
    </Flex>
  );
};
