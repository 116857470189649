import { Flex } from "components/Flex";
// import { Tooltip } from "components/Tooltip";
import { theme } from "styles/theme";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "accent";
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  outlined?: boolean;
  tooltipContent?: string;
  size?: "sm" | "md" | "lg";
}

interface StyledButtonI {
  size: "sm" | "md" | "lg";
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  outlined?: ButtonI["outlined"];
}

const { palette } = theme;

const colorVariantsMap = {
  primary: palette.text.secondary,
  secondary: palette.text.secondary,
  accent: palette.text.secondary,
};

const colorHoverVariantsMap = {
  primary: palette.text.secondary,
  secondary: palette.text.secondary,
  accent: palette.text.secondary,
};

const colorActiveVariantsMap = {
  primary: palette.text.secondary,
  secondary: palette.text.secondary,
  accent: palette.text.secondary,
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.secondary.main,
  accent: palette.accentColor.main,
};

const backgroundColorHoverVariantsMap = {
  primary: palette.primary.dark,
  secondary: palette.secondary.dark,
  accent: palette.accentColor.dark,
};

const backgroundColorActiveVariantsMap = {
  primary: palette.primary.light,
  secondary: palette.secondary.light,
  accent: palette.accentColor.light,
};

const StyledButton = styled.button<StyledButtonI>`
  font-family: Open Sans, sans-serif;
  ${({ variant, icon, fullWidth, outlined, size }) => `
    display: ${fullWidth ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;    
    ${fullWidth ? `width: 100%;` : ""};
    height: fit-content;
    min-width: 120px;
    border-radius: 10px;    
    font-weight: 600;
    font-size: 14px;
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    ${
      outlined
        ? `
          box-shadow: inset 0px 0px 0px 1px;
          color: ${backgroundColorVariantsMap[variant]};
          background-color: transparent;
          border: 0;
        `
        : "border: 0;"
    }
    outline: none;
    cursor: pointer;
    ${icon ? "min-width: unset; width: 40px; height: 40px;" : ""}
    ${!icon ? "padding: 10px 30px;" : "padding: 10px;"}
    ${!icon && size === "sm" && "padding: 6px 20px;"}

    &:hover {
      color: ${colorHoverVariantsMap[variant]};
      background-color: ${backgroundColorHoverVariantsMap[variant]};
      ${
        outlined &&
        `
        color: ${backgroundColorHoverVariantsMap[variant]};
        background-color: transparent;
      `
      }
      svg path {
        fill: ${colorHoverVariantsMap[variant]};
      }
    }

    &:active {
      color: ${colorActiveVariantsMap[variant]};
      background-color: ${backgroundColorActiveVariantsMap[variant]};
      ${
        outlined &&
        `
        color: ${backgroundColorActiveVariantsMap[variant]};
        background-color: transparent;
      `
      }
    }

    &:disabled {
      pointer-events: none;
      color: ${theme.palette.text.secondary};
      background-color: grey;
      ${
        outlined &&
        `
        color: grey;
        background-color: transparent;
      `
      }
    }
  `}
  transition: 100ms ease-in all;
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  outlined,
  variant = "primary",
  type = "button",
  disabled = false,
  fullWidth,
  onClick,
  children,
  size = "md",
  tooltipContent,
}) => (
  // <Tooltip fullWidth={fullWidth} content={tooltipContent}>
  <StyledButton
    type={type}
    icon={icon}
    size={size}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    outlined={outlined}
    onClick={onClick}
  >
    <Flex justifyContent="center" alignItems="center">
      {label}
      {icon}
      {children && (
        <Flex mx={1} alignItems="center" gap="10px">
          {children}
        </Flex>
      )}
    </Flex>
  </StyledButton>
  // </Tooltip>
);
