import { ReactComponent as Appointments } from "assets/icons/nav-appointments.svg";
import { ReactComponent as Therapy } from "assets/icons/nav-therapy.svg";
import { ReactComponent as Account } from "assets/icons/nav-my-account.svg";
import { ReactComponent as Calendar } from "assets/icons/nav-calendar.svg";
import { ReactComponent as ExpertCalendar } from "assets/icons/nav-expert-calendar.svg";
import { ReactComponent as Patients } from "assets/icons/nav-patients.svg";

export const sidebarItems = [
  {
    text: "Wizyty",
    path: "/visits",
    icon: <Appointments />,
    role: ["Patient"],
  },
  {
    text: "Terapia",
    path: "/therapy",
    icon: <Therapy />,
    role: ["Patient"],
  },
  {
    text: "Kalendarz wizyt",
    path: "/visits-calendar",
    icon: <Calendar />,
    role: ["Expert"],
  },
  {
    text: "Kalendarz specjalisty",
    path: "/specialists-calendar",
    icon: <ExpertCalendar />,
    role: ["Expert"],
  },
  {
    text: "Pacjenci",
    path: "/patients",
    icon: <Patients />,
    role: ["Expert"],
  },
  {
    text: "Moje konto",
    path: "/my-account",
    icon: <Account />,
    role: ["Patient", "Expert"],
  },
];
