import { Avatar, Flex } from "components";
import { H6, P } from "components/Typography";
import { Button } from "components/_form";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { device } from "styles/theme";
import { IExpert } from "types/experts";

interface ISpecialistTile {
  specialistData: IExpert;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  width: 100%;
  padding: 20px;
  align-items: center;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const SpecialistsTile = ({ specialistData }: ISpecialistTile) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Flex flexDirection="row" gap="20px">
        <Avatar />
        <Flex flexDirection="column" width="100%">
          <H6 variant="h6">
            {specialistData.profile?.first_name}{" "}
            {specialistData.profile?.last_name}
          </H6>
          <P variant="body">Specjalność</P>
        </Flex>
      </Flex>
      <Button
        label="Umów wizytę"
        onClick={() => navigate(`/visits/add/specialist/${specialistData.id}`)}
      />
    </Wrapper>
  );
};

export default SpecialistsTile;
