import { Flex, Spinner } from "components";
import { H6, P } from "components/Typography";
import { Button, TextField } from "components/_form";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "styles/theme";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/bin-icon.svg";
import { useForm } from "react-hook-form";
import { IProfile } from "types/users";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateProfile } from "api/users";
import { useWindowWidth } from "hooks/useWindowWidth";
import { UserContext } from "contexts/UserContext";
import { useTranslation } from "react-i18next";
import { profileSchema } from "validation/authSchemas";

const Wrapper = styled(Flex)`
  flex-flow: column nowrap;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.neutral.grey};
  padding: 20px;
  border-radius: 10px;
  gap: 10px;
  align-items: center;
`;

const Grid = styled.fieldset`
  width: 100%;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;
const EditDeleteButton = styled(Flex)`
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

export const ProfileForm = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useWindowWidth();
  const { userData, updateData } = useContext(UserContext);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<Omit<IProfile, "avatar">>({
    mode: "onChange",
    resolver: yupResolver(profileSchema),
  });

  const resetForm = () => {
    reset({
      first_name: userData.firstName,
      last_name: userData.lastName,
      phone_number: userData.phone,
    });
  };

  useEffect(() => {
    resetForm();
  }, [userData]);

  const handleUpdate = handleSubmit(async (data) => {
    setIsEditMode(false);
    setIsLoading(true);
    try {
      const response = await updateProfile(data);
      if (response.status === 200) {
        updateData(response.data.data);
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  });

  const buttons = (
    <>
      <Button
        outlined
        label="Anuluj"
        onClick={() => {
          resetForm();
          setIsEditMode(false);
        }}
      />
      <Button label="Zapisz" disabled={!(isDirty && isValid)} type="submit" />
    </>
  );

  return (
    <form onSubmit={handleUpdate}>
      <Flex flexDirection="column" gap="10px">
        <Wrapper>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <H6 variant="h6">Dane użytkownika</H6>
            {isLoading ? (
              <Spinner />
            ) : !isEditMode ? (
              <EditDeleteButton onClick={() => setIsEditMode(true)}>
                <P variant="body">Edytuj profil</P>
                <EditIcon />
              </EditDeleteButton>
            ) : (
              <></>
            )}
          </Flex>

          <Grid disabled={!isEditMode}>
            <TextField
              {...register("first_name")}
              label="Imię:"
              required
              error={t(errors.first_name?.message || "")}
            />
            <TextField
              {...register("last_name")}
              label="Nazwisko:"
              required
              error={t(errors.last_name?.message || "")}
            />
            <TextField
              {...register("phone_number")}
              label="Telefon:"
              required
              error={t(errors.phone_number?.message || "")}
            />
          </Grid>
        </Wrapper>
        <Flex justifyContent="space-between">
          <EditDeleteButton height="39px">
            <P variant="body">Usuń konto</P>
            <DeleteIcon />
          </EditDeleteButton>
          {isEditMode && !isMobile && <Flex gap="10px">{buttons}</Flex>}
        </Flex>
        {isEditMode && isMobile && (
          <Flex width="100%" justifyContent="center" gap="10px">
            {buttons}
          </Flex>
        )}
      </Flex>
    </form>
  );
};
