import { FC, useEffect, useState } from "react";
import { Flex } from "components";
import { FaqAccordion } from "./FaqAccordion";
import { IFaqs } from "types/faq";
import { getFaqs } from "services/faq";
import { H2 } from "components/Typography";

export const FaqView: FC = () => {
  const [faqs, setFaqs] = useState<IFaqs>();

  useEffect(() => {
    getFaqs().then((res) => setFaqs(res));
  }, []);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="85%"
    >
      <H2 variant="h2" mb={4}>
        Najczęściej zadawane pytania
      </H2>
      {faqs?.data.map((el) => (
        <FaqAccordion key={el.id} question={el.question} answer={el.answer} />
      ))}
    </Flex>
  );
};
