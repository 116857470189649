import styled from "styled-components";
import { Flex } from "components";
import { device } from "styles/theme";

export const Wrapper = styled.main`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `}
  width:100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`;

export const Content = styled(Flex)`
  flex-grow: 2;
  flex-flow: row nowrap;
  width: 100%;
  padding-block: 40px;
  padding-inline: 160px;
  @media ${device.desktop} {
    padding-inline: 80px;
  }
  @media ${device.laptop} {
    padding-inline: 40px;
  }
  @media ${device.tablet} {
    padding-inline: 10px;
    flex-flow: column nowrap;
  }
  gap: 20px;
`;

export const AuthContent = styled(Content)`
  background-color: ${({ theme }) => theme.palette.backgroundGuest};
  @media ${device.tablet} {
    padding-inline: 0px;
  }
`;

export const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  height: auto;
`;
