import { Calendar } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";

export const StyledCalendar = styled(Calendar)`
  width: 100%;
  position: relative;

  &.rbc-calendar {
    border-radius: 10px;
    border: 1px solid #ddd;
    overflow: hidden;
  }

  .rbc-time-view {
    border-inline: 0px;
    border-bottom: 0px;
  }

  .rbc-row-resource {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .rbc-time-gutter.rbc-time-column {
    width: 62px;
    border-right: 1px solid #ddd;
    background-color: #ffffff;
    z-index: 2;
    position: sticky;
    left: 0;
  }

  .rbc-time-header-gutter {
    border-right: 1px solid #ddd;
    background-color: #ffffff;
    z-index: 2;
    position: sticky;
    left: 0;
  }

  & .rbc-allday-cell {
    display: none;
  }

  .rbc-time-content {
    border-top: 1px solid #ddd;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .rbc-header {
    text-align: start;
    border-bottom: 0px;
    min-width: 200px;
    padding: 10px;
    .rbc-button-link {
      cursor: default;
    }
  }

  .rbc-header.rbc-today {
    background: ${({ theme }) => theme.palette.primary.main};
    color: #ffffff;
  }

  .rbc-time-header {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .rbc-time-column {
    :not(:nth-child(1)) {
      min-width: 200px;
    }
  }

  .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
    pointer-events: none;
    background-color: ${({ theme }) => theme.palette.backgroundGuest};
  }

  .rbc-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding: 0px 12px;
  }

  .rbc-event-label {
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
  }

  .rbc-event-content > :first-child {
    & > :first-child {
      font-weight: 600;
      font-size: 9px;
      line-height: 10px;
    }
    & > :nth-child(2) {
      font-weight: 700;
      font-size: 9px;
      line-height: 10px;
    }
  }

  .rbc-events-container {
    border: none;
    margin: 0 4px;
  }

  .rbc-timeslot-group {
    min-height: 80px;
  }

  .rbc-event {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border: none;
    flex-flow: column nowrap;
  }

  .rbc-current-time-indicator {
    background-color: ${({ theme }) => theme.palette.primary.main};
    height: 2px;
  }
`;

export const HeaderDayName = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const HeaderDayNumber = styled.span`
  font-size: 21px;
  font-weight: 600;
`;
