import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, MenuItem, Label } from "./Menu.styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "contexts/UserContext";
import { IUserType } from "types/users";

export interface IMenuItem {
  label: string;
  icon?: IconProp;
  path: string;
  role?: IUserType[];
}

export interface IMenu {
  items?: Array<IMenuItem>;
  view?: "horizontal" | "vertical";
  onItemClick?: () => void;
}

export const Menu = ({ items, view = "horizontal", onItemClick }: IMenu) => {
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = useContext(UserContext);

  const filteredItems = items?.filter((e) =>
    e.role?.find((el) => el === userRole),
  );

  const renderMenuItems = () => {
    if (!filteredItems) return;
    return filteredItems.map(({ label, icon, path }) => {
      const isItemSelected = location.pathname === path;

      return (
        <MenuItem
          active={isItemSelected}
          alignItems="center"
          key={label}
          onClick={() => {
            if (onItemClick) onItemClick();
            navigate(path);
          }}
        >
          <Label>{t(label)}</Label>
          {icon && <FontAwesomeIcon icon={icon} fontSize="25px" />}
        </MenuItem>
      );
    });
  };

  return (
    <Wrapper
      flexDirection={view === "horizontal" ? "column" : "row"}
      ref={menuRef}
      gap="40px"
      mx="20px"
    >
      {renderMenuItems()}
    </Wrapper>
  );
};
