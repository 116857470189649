import { ENTRIES } from "constants/paths";
import request from "./index";
import qs from "qs";
import { IGetEntries, IGetEntry } from "types/entries";

interface IGetEntriesFilters {
  page: number;
  per_page: number;
}

export const getEntries = (filters?: IGetEntriesFilters) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return request<IGetEntries>({
    options: {
      url: `${ENTRIES}?${query}`,
    },
  });
};

export const getEntry = (slug?: string) => {
  return request<IGetEntry>({
    options: {
      url: `${ENTRIES}/${slug ? slug : ""}`,
    },
  });
};
