import React from "react";
import { Footer } from "layouts/components/Footer";
import { Navbar } from "layouts/components/Navbar";
import { Wrapper, Image, AuthContent } from "./Layout.styled";
import GuestViewImg from "assets/images/guest-view.png";
import { Flex } from "components";
import { useWindowWidth } from "hooks/useWindowWidth";

export const AuthLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isMobile } = useWindowWidth();
  return (
    <Wrapper>
      <Navbar />
      <AuthContent alignItems="center">
        {children}
        {!isMobile && (
          <Flex width="100%" flexGrow={0}>
            <Image src={GuestViewImg} />
          </Flex>
        )}
      </AuthContent>
      <Footer variant="white" />
    </Wrapper>
  );
};
