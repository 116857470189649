import styled from "styled-components";
import { sidebarItems } from "constants/sidebarItems";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Box } from "components";
import { useAccount } from "hooks/useAccount";

const Wrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  background-color: white;
  height: 80px;
  width: 100%;
  padding: 8px 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: nowrap;
  justify-items: center;
  overflow: scroll;
  gap: 10px;
  z-index: 2;
`;

const Item = styled(Flex)<{ active: boolean }>`
  min-width: 100px;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.palette.text.primary};
  }

  svg {
    flex-shrink: 0;
  }

  ${({ active, theme }) =>
    active
      ? `color: ${theme.palette.text.secondary}; background-color: ${theme.palette.primary.main}; svg path {
        stroke: ${theme.palette.text.secondary}
      }`
      : ``};
`;

export const MobileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = useAccount();
  return (
    <Wrapper>
      {sidebarItems
        .filter((e) => e.role.find((el) => el === userRole))
        .map((e, k) => (
          <Item
            key={k}
            onClick={() => navigate(e.path)}
            active={location.pathname.includes(e.path)}
          >
            {e.icon}
            {e.text}
          </Item>
        ))}
    </Wrapper>
  );
};
