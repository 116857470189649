import axios, { AxiosError, AxiosResponse } from "axios";
import { IAxiosConfig, IRequestOptions } from "types/common";

const request = <T>({
  options = { url: "/", method: "get" },
  headers = {},
  handleSubmit,
}: IRequestOptions): Promise<AxiosResponse<T>> => {
  const authToken = localStorage.getItem("auth-token");
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: authToken,
      ...headers,
    },
    responseType: "json",
  });

  const defaultOnSuccess = (response: AxiosResponse<T>) => {
    return response;
  };

  const onSuccess = handleSubmit ? handleSubmit : defaultOnSuccess;

  // todo later
  const onError = async (error: AxiosError) => {
    // tmp
    if (error?.response?.status === 401) {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("auth-refresh-token");
      document.location.href = "/login";
    } else {
      throw new Error(error.message || "An error occurred");
    }
    // tmp

    const originalRequest: IAxiosConfig = error.config as IAxiosConfig;

    if (error?.response?.status === 401 && !originalRequest.isRetryRequest) {
      originalRequest.isRetryRequest = true;
      const refreshToken = localStorage.getItem("auth-refresh-token");

      await axios
        .put(process.env.REACT_APP_API_URL + "/auth/refresh", { refreshToken })
        .then((res) => {
          const { accessToken, refreshToken } = res.data;

          localStorage.setItem("auth-token", accessToken);
          localStorage.setItem("auth-refresh-token", refreshToken);

          originalRequest.headers &&
            (originalRequest.headers[
              "Authorization"
            ] = `Bearer ${accessToken.value}`);

          return client.request(originalRequest);
        })
        .catch((reason) => {
          if (
            reason.response.status === 400 ||
            reason.response.status === 404
          ) {
            localStorage.removeItem("auth-token");
            localStorage.removeItem("auth-refresh-token");
          }
        });
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
