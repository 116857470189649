import { REGULATIONS } from "constants/paths";
import { IRegulations } from "types/regulations";
import request from "./index";

export const getRegulations = () =>
  request<IRegulations>({
    options: {
      url: REGULATIONS,
    },
  });
