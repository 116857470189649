import { AxiosResponse } from "axios";
import request from "./index";
import {
  ILogin,
  ILoginResponse,
  IRegister,
  IResetPassword,
} from "types/forms/auth";

export const login = (data: ILogin) =>
  request<ILoginResponse>({
    options: {
      url: "/login",
      method: "post",
      data: { user: data },
    },
    handleSubmit: (response) => {
      if (
        response.headers.authorization &&
        !localStorage.getItem("auth-token")
      ) {
        localStorage.setItem("auth-token", response.headers.authorization);
      }
      if (response.data.data)
        localStorage.setItem("currentUser", JSON.stringify(response.data.data));

      return response;
    },
  });

export const register = (data: IRegister) =>
  request<ILoginResponse>({
    options: {
      url: "/signup",
      method: "post",
      data: { user: data },
    },
  });

export const resetPassword = (data: IResetPassword) =>
  request({
    options: {
      url: "/password",
      method: "post",
      data: { user: data },
    },
  });

export const logout = () =>
  request<string>({
    options: {
      url: "/logout",
      method: "delete",
    },
    handleSubmit: (response: AxiosResponse<string>) => {
      localStorage.clear();
      return response;
    },
  });
