import { getVisits } from "api/visits";
import { Grid, MyCalendar } from "components";
import { useCallback, useEffect, useState } from "react";
import { Event, ToolbarProps, Views } from "react-big-calendar";
import { toast } from "react-toastify";
import { getUserFullName } from "utilities/user";
import { StyledToolbar, ToolbarButton } from "./VisitsCalendarView.styled";
import { ReactComponent as BackIcon } from "assets/icons/chevron-left.svg";
import moment from "moment";
import { H3, P } from "components/Typography";
import { useTranslation } from "react-i18next";

export const VisitsCalendarView = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<Event[]>([]);
  const [currentDate, setCurrentDate] = useState<moment.Moment>(
    moment(new Date()),
  );

  const handleVisits = useCallback(async () => {
    try {
      const response = await getVisits();
      if (response.status === 200) {
        let eventsList: Event[] = [];
        response.data.data.map((el) => {
          eventsList.push({
            start: new Date(el.start_at),
            end: new Date(el.finish_at),
            title: getUserFullName(el.patient.profile),
          });
        });

        setEvents(eventsList);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
    }
  }, []);

  useEffect(() => {
    handleVisits();
  }, [handleVisits]);

  const Toolbar = ({ date }: ToolbarProps) => (
    <StyledToolbar>
      <ToolbarButton
        onClick={() => {
          setCurrentDate(moment(date).subtract(1, "week"));
        }}
      >
        <BackIcon />
      </ToolbarButton>
      <P variant="body" fontWeight={600}>
        {moment(date).format("MMMM yyyy")}
      </P>
      <ToolbarButton
        onClick={() => {
          setCurrentDate(moment(date).add(1, "week"));
        }}
      >
        <BackIcon transform="rotate(180)" />
      </ToolbarButton>
    </StyledToolbar>
  );

  return (
    <Grid gridTemplateColumns="1fr" width="100%" gap="10px">
      <H3 variant="h3">{t("visitsView.visitsCalendar")}</H3>
      <MyCalendar
        events={events}
        toolbarContent={Toolbar}
        date={currentDate.toDate()}
        views={["week"]}
        defaultView={Views.WEEK}
      />
    </Grid>
  );
};
