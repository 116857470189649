import { ReactComponent as AvatarIcon } from "assets/icons/avatar.svg";
import { BLOB_URL } from "constants/paths";
import React from "react";
import styled from "styled-components";

export interface AvatarI {
  size?: "small" | "medium" | "large";
  url?: string;
}

interface RootI {
  size: AvatarI["size"];
}

interface ImageI {
  url?: AvatarI["url"];
}

const Root = styled.div<RootI>`
  ${({ size, theme }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: ${
      (size === "small" && `30px`) ||
      (size === "medium" && `46px`) ||
      (size === "large" && `64px`)
    };
    aspect-ratio: 1/1;
    flex: 0 0 auto;
    border-radius: 50%;
    background-color: ${theme.palette.primary.main};
    overflow: hidden;
  `}
`;

const Image = styled.div<ImageI>`
  ${({ url }) => `
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    ${url && `background-image: url('${BLOB_URL + url}');`}
    background-position: center;
    background-size: cover;
  `}
`;

export const Avatar: React.FC<AvatarI> = ({ size = "medium", url }) => (
  <Root size={size}>
    <AvatarIcon />
    <Image url={url} />
  </Root>
);
