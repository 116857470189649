import { Flex } from "components/Flex";
import { Dispatch, SetStateAction } from "react";
import { ReactComponent as Back } from "assets/icons/chevron-left.svg";
import styled from "styled-components";

interface IPagination {
  current: number;
  total: number;
  setCurrent: Dispatch<SetStateAction<number>>;
}

const Button = styled(Flex)<{ disabled: boolean }>`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.neutral.lightGrey : theme.palette.secondary.main};
  ${({ disabled }) => disabled && "cursor:auto"}
  :hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && `${theme.palette.secondary.light}`};
  }
  transition: 150ms linear;
`;

export const Pagination = ({ current, total, setCurrent }: IPagination) => {
  return (
    <Flex gap="10px" justifyContent="center" alignItems="center">
      <Button
        disabled={current <= 1}
        onClick={() => {
          if (current > 1) setCurrent((prev) => prev - 1);
        }}
      >
        <Back />
      </Button>

      <span>{current}</span>
      <span>{"z"}</span>
      <span>{total}</span>
      <Button
        disabled={current === total}
        onClick={() => {
          if (current < total) setCurrent((prev) => prev + 1);
        }}
      >
        <Back transform="rotate(180)" />
      </Button>
    </Flex>
  );
};
