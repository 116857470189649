import styled from "styled-components";
import EULogosImage from "assets/images/eu-logos.png";
import { useLocation, useNavigate } from "react-router-dom";
import { P } from "components/Typography";
import { Flex } from "components/Flex";

const Wrapper = styled(Flex)`
  cursor: pointer;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const StyledImg = styled.img`
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  height: auto;
  background-color: #fff;
`;

interface IEULogosProps {
  showText?: boolean;
}

export const EULogos = ({ showText = false }: IEULogosProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Wrapper
      onClick={() => {
        if (pathname !== "/info/project") navigate("/info/project");
      }}
    >
      {showText && (
        <P variant="body2" fontWeight={600} textAlign="center">
          Sfinansowano w ramach reakcji Unii na pandemię COVID 19, realizujemy
          projekt ze wsparciem z REACT-EU W RAMACH DZIAŁANIA 6.2 WSPARCIE MŚP W
          OBSZARZE CYFRYZACJI - BONY NA CYFRYZACJĘ W RAMACH PROGRAMU
          OPERACYJNEGO INTELIGENTNY ROZWÓJ 2014-2020
        </P>
      )}
      <StyledImg src={EULogosImage} alt="eu logos" />
    </Wrapper>
  );
};
