import { updateProfile } from "api/users";
import { Avatar, Flex, Spinner } from "components";
import { P } from "components/Typography";
import { useAccount } from "hooks/useAccount";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled(Flex)`
  cursor: pointer;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.neutral.grey};
  padding: 20px;
  border-radius: 10px;
  gap: 10px;
  align-items: center;
`;

export const AddAvatar = () => {
  const { userData } = useAccount();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>();

  const handleUpdate = async () => {
    try {
      const response = await updateProfile({ avatar: file });
      if (response.status === 200) {
        console.log("success");
      } else {
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      setIsLoading(true);
      handleUpdate();
    }
  }, [file]);

  return (
    <Wrapper
      onClick={() => {
        fileInputRef.current?.click();
      }}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".jpg, .png"
        onChange={(event) => {
          if (event.target.files) {
            setFile(event.target.files[0]);
          }
        }}
      />
      <Avatar url={userData.avatar} />
      {isLoading ? (
        <Spinner />
      ) : (
        <P variant="body">
          {userData.avatar
            ? "Edytuj zdjęcie profilowe"
            : "Dodaj zdjęcie profilowe"}
        </P>
      )}
    </Wrapper>
  );
};
