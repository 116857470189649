import { FC, useEffect, useState } from "react";
import { Box, Card, Flex, Link, Spinner, Typography } from "components";
import { Button, Checkbox, TextField } from "components/_form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "validation/authSchemas";
import { IRegister } from "types/forms/auth";
import { register as registerRec } from "../api/authApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H4, P } from "components/Typography";
import { useWindowWidth } from "hooks/useWindowWidth";
import { IRegulation } from "types/regulations";
import { getRegulations } from "api/regulations";

export const RegisterView: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useWindowWidth();
  const [wasSuccess, setWasSuccess] = useState(false);
  const [regulations, setRegulations] = useState<IRegulation[]>();
  const [regulationsAccepted, setRegulationsAccepted] = useState(false);
  const [acceptedRequiredRegulations, setAcceptedRequiredRegulations] =
    useState<number[]>([]);
  const [acceptedCommonRegulations, setAcceptedCommonRegulations] = useState<
    number[]
  >([]);
  const [requiredRegulationIds, setRequiredRegulationIds] = useState<number[]>(
    [],
  );

  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IRegister>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      type: "Patient",
      regulation_ids: [],
    },
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await registerRec(data);
      if (response.status === 201) {
        setWasSuccess(true);
      }
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data.data);
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  const handleCommonRegulations = (id: number) => {
    setAcceptedCommonRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  const handleRequiredRegulations = (id: number) => {
    setAcceptedRequiredRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  useEffect(() => {
    setValue("regulation_ids", acceptedRequiredRegulations);
  }, [acceptedRequiredRegulations]);

  useEffect(() => {
    if (!regulations) return;
    let temp: number[] = [];
    regulations.map((e) => {
      if (e.required) temp.push(e.id);
    });
    setRequiredRegulationIds(temp);
  }, [regulations]);

  useEffect(() => {
    setRegulationsAccepted(
      acceptedRequiredRegulations.length === requiredRegulationIds.length &&
        acceptedRequiredRegulations.every(
          (v, i) => v === requiredRegulationIds[i],
        ),
    );
  }, [requiredRegulationIds, acceptedRequiredRegulations]);

  useEffect(() => {
    if (wasSuccess) reset();
  }, [wasSuccess]);

  return (
    <Card
      isMobile={isMobile}
      showBackButton={wasSuccess}
      onBackClick={() => setWasSuccess(false)}
    >
      <form onSubmit={onSubmit}>
        <Flex
          flexDirection="column"
          width="100%"
          paddingX={["0px", "40px", "80px"]}
          gap="20px"
        >
          <H4 variant="h4" textAlign="center">
            {t("registerView.register")}
          </H4>
          {!wasSuccess ? (
            <>
              <Flex flexDirection="column" gap="10px">
                <TextField
                  label={t("form.email.label")}
                  placeholder={t("form.email.placeholder")}
                  required
                  {...register("email")}
                  error={t(errors.email?.message || "")}
                />
                <TextField
                  type="password"
                  required
                  label={t("form.password.label")}
                  placeholder={t("form.password.placeholder")}
                  {...register("password")}
                  error={t(errors.password?.message || "")}
                />
                <TextField
                  type="password"
                  required
                  label={t("form.confirmPassword.label")}
                  placeholder={t("form.confirmPassword.placeholder")}
                  {...register("password_confirmation")}
                  error={t(errors.password_confirmation?.message || "")}
                />
                {regulations?.map((regulation) => (
                  <Box
                    key={regulation.id}
                    display="flex"
                    justifyContent="start"
                    mb={1}
                    alignItems="center"
                  >
                    <Checkbox
                      onChange={() => {
                        !!regulation.required
                          ? handleRequiredRegulations(regulation.id)
                          : handleCommonRegulations(regulation.id);
                      }}
                      checked={
                        !!regulation.required
                          ? !!acceptedRequiredRegulations.find(
                              (el) => el === regulation.id,
                            )
                          : !!acceptedCommonRegulations.find(
                              (el) => el === regulation.id,
                            )
                      }
                    />
                    <Flex gap="5px">
                      <Link
                        to={regulation.link && `/info${regulation.link}`}
                        target={regulation.link && "_blank"}
                      >
                        {regulation.name}
                      </Link>
                      {regulation.required && (
                        <P color="red" variant="body">
                          *
                        </P>
                      )}
                    </Flex>
                  </Box>
                ))}
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="submit"
                    disabled={!(formState.isValid && regulationsAccepted)}
                  >
                    {t("buttons.register")}
                  </Button>
                )}
              </Flex>
              <Flex alignItems="center" justifyContent="center" gap="5px">
                <Typography.P variant="body" color="primary">
                  {t("registerView.accountAllready")}
                </Typography.P>
                <Link to="/login">{t("buttons.login")}</Link>
              </Flex>
            </>
          ) : (
            <>
              <P variant="body" textAlign="center">
                {t("registerView.linkToActivate")}
              </P>
              <Button>{t("buttons.login")}</Button>
            </>
          )}
        </Flex>
      </form>
    </Card>
  );
};
