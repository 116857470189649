import { Flex, Header, Spinner } from "components";
import { P } from "components/Typography";
import ServiceTile from "./components/ServiceTile";
import { useEffect, useState } from "react";
import { IService } from "types/services";
import { toast } from "react-toastify";
import { getServices } from "api/services";
import { useTranslation } from "react-i18next";
const ServicesView = () => {
  const [services, setServices] = useState<IService[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const handleServices = async () => {
    try {
      const { data } = await getServices();
      if (!data) {
        toast.error("Brak danych");
        return;
      } else {
        setServices(data.data);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleServices();
  }, []);

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Header text={t("servicesView.services")} />
      {isLoading ? (
        <Spinner />
      ) : services.length > 0 ? (
        services.map((e) => <ServiceTile key={e.id} serviceData={e} />)
      ) : (
        <P variant="body" color="grey">
          {t("servicesView.noServices")}
        </P>
      )}
    </Flex>
  );
};

export default ServicesView;
