import { Avatar, Box, Flex } from "components";
import { P } from "components/Typography";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "styles/theme";
import { IPatient } from "types/patients";

interface IPatientTile {
  patient: IPatient;
}

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 0.8fr;
  gap: 20px;
  align-items: center;

  @media ${device.tablet} {
    grid-template-columns: minmax(auto, 1fr);
  } ;
`;

export const PatientTile = ({ patient }: IPatientTile) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Flex flexDirection="row" gap="20px" alignItems="center">
        <Avatar />
        <P variant="body" fontWeight={600}>
          {patient.profile?.first_name} {patient.profile?.last_name}
        </P>
      </Flex>
      <P variant="body">
        PESEL: <b>-</b>
      </P>
      <Button
        label="Karta pacjenta"
        onClick={() => navigate(`/patients/${patient.id}`)}
      />
    </Wrapper>
  );
};
