import { FC, useContext, useState } from "react";
import { Card, Flex, Link, Spinner, Typography } from "components";
import { Button, Checkbox, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validation/authSchemas";
import { ILogin } from "types/forms/auth";
import { login } from "../api/authApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H4 } from "components/Typography";
import { useWindowWidth } from "hooks/useWindowWidth";
import { UserContext } from "contexts/UserContext";

export const LoginView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useWindowWidth();

  const { updateData } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<ILogin>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await login(data);
      if (response.status === 200) {
        navigate("/");
      } else return;
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Card isMobile={isMobile}>
      <form onSubmit={onSubmit}>
        <Flex
          flexDirection="column"
          gap="20px"
          width="100%"
          paddingX={["0px", "40px", "80px"]}
        >
          <H4 variant="h4" textAlign="center">
            {t("loginView.login")}
          </H4>
          <Flex flexDirection="column" gap="10px">
            <TextField
              label={t("form.email.label")}
              placeholder={t("form.email.placeholder")}
              {...register("email")}
              error={t(errors.email?.message || "")}
              required
            />

            <TextField
              type="password"
              label={t("form.password.label")}
              placeholder={t("form.password.placeholder")}
              {...register("password")}
              error={t(errors.password?.message || "")}
              required
            />

            <Flex
              display="flex"
              justifyContent="space-between"
              alignContent="center"
            >
              <Checkbox label={t("loginView.rememberMe")} />
              <Link to="/password-reset">{t("loginView.remindPassword")}</Link>
            </Flex>

            {isLoading ? (
              <Spinner />
            ) : (
              <Button
                variant="secondary"
                type="submit"
                disabled={!formState.isValid}
              >
                {t("buttons.login")}
              </Button>
            )}
          </Flex>
          <Flex alignItems="center" justifyContent="center" gap="5px">
            <Typography.P variant="body" color="primary">
              {t("loginView.accountless")}
            </Typography.P>
            <Link to="/register">{t("buttons.register")}</Link>
          </Flex>
        </Flex>
      </form>
    </Card>
  );
};
