import styled from "styled-components";

export const Line = styled.span`
  ${({ theme }) => `
	    display: flex;
      background-color: ${theme.palette.neutral.lightGrey};
      width: 100%;
      height: 1px;
  `};
`;
