import { Flex, Header, Pagination, Spinner } from "components";
import { P } from "components/Typography";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IExpert } from "types/experts";
import SpecialistsTile from "./components/SpecialistsTile";
import { TextField } from "components/_form";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { getExperts } from "api/experts";

const SpecialistsView = () => {
  const [specialists, setSpecialists] = useState<IExpert[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const handleSpecialists = async () => {
    setIsLoading(true);
    try {
      const response = await getExperts({
        page: currentPage,
        per_page: 5,
      });
      if (response.status === 200) {
        setSpecialists(response.data.data);
        setTotalPages(response.data.pagination?.pages ?? 0);
      } else {
        toast.error(response.statusText);
        return;
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSpecialists();
  }, [currentPage]);

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Header text={"Poznaj naszych specjalistów"} />

      {isLoading ? (
        <Spinner />
      ) : specialists.length > 0 ? (
        <>
          {specialists.map((e) => (
            <SpecialistsTile key={e.id} specialistData={e} />
          ))}
          <Pagination
            current={currentPage}
            total={totalPages}
            setCurrent={setCurrentPage}
          />
        </>
      ) : (
        <P variant="body" color="grey">
          Brak specialistów do wyświetlenia
        </P>
      )}
    </Flex>
  );
};

export default SpecialistsView;
