import { Flex } from "components/Flex";
import styled, { css } from "styled-components";
import {
  space,
  SpaceProps,
  LayoutProps,
  layout,
  flexbox,
  FlexboxProps,
  gridColumnGap,
} from "styled-system";
import { ReactComponent as BackIcon } from "assets/icons/chevron-left.svg";

interface ICard extends SpaceProps, LayoutProps, FlexboxProps {
  children: React.ReactNode;
  isMobile?: boolean;
  showBackButton?: boolean;
  onBackClick?(): void;
}

const SCard = styled.div<ICard>(
  ({ isMobile }) =>
    css`
      ${({ theme }) => `
    width: 100%;
    padding: 20px;
    border-radius: ${isMobile ? "0px" : "10px"} ;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0 6px 24px -12px ${theme.palette.neutral.medium};
  `};
      position: relative;
    `,
  space,
  flexbox,
  gridColumnGap,
  layout,
);

const Back = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
`;

export const Card = (props: ICard) => {
  return (
    <SCard {...props}>
      {props.showBackButton && (
        <Back onClick={props.onBackClick}>
          <BackIcon />
        </Back>
      )}
      {props.children}
    </SCard>
  );
};
