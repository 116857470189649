import { Flex } from "components";
import { Label } from "components/Menu/Menu.styles";
import { H4, P } from "components/Typography";
import { Radio } from "components/_form";
import { useWindowWidth } from "hooks/useWindowWidth";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ElementWrapper } from "../AddVisitView.styled";

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;
  accent-color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

const KindOption = styled.label<{ active?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
  ${({ active, theme }) =>
    active
      ? `border: 1px solid ${theme.palette.primary.main};`
      : `border: 1px solid ${theme.palette.neutral.lightGrey};`}
`;

export const Kind = () => {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const { isMobile } = useWindowWidth();
  const kind = watch("kind");
  return (
    <ElementWrapper>
      <H4 variant="h4">Wybierz formę wizyty</H4>
      <Flex flexDirection={isMobile ? "column" : "row"} width="100%" gap="10px">
        <KindOption htmlFor="in_person" active={kind === "in_person"}>
          <RadioInput
            id="in_person"
            value="in_person"
            type="radio"
            {...register("kind")}
          />
          <Flex flexDirection="column" gap="5px">
            <P variant="body">Stacjonarnie</P>
            <P variant="body2">Stacjonarnie</P>
          </Flex>
        </KindOption>

        <KindOption htmlFor="online" active={kind === "online"}>
          <RadioInput
            id="online"
            value="online"
            type="radio"
            {...register("kind")}
          />
          <Flex flexDirection="column" gap="5px">
            <P variant="body">Online</P>
            <P variant="body2">Poprzez Jitsy Meeting</P>
          </Flex>
        </KindOption>

        <KindOption htmlFor="telephone" active={kind === "telephone"}>
          <RadioInput
            id="telephone"
            value="telephone"
            type="radio"
            {...register("kind")}
          />
          <Flex flexDirection="column" gap="5px">
            <P variant="body">Telefonicznie</P>
            <P variant="body2">Teleporada</P>
          </Flex>
        </KindOption>
      </Flex>
    </ElementWrapper>
  );
};
