export const contactDescription =
  "Dermativa powstała z myślą o poprawie komfortu życia codziennego wszystkich, którym doskwierają problemy ze stopami. Początkowo gabinet zajmował się sprawami typowo podologicznym- opracowywaniem paznokci i hiperkeratoz, terapii przeciwwirusowych i przeciwbakteryjnych w obrębie stóp, korekcją wrastających paznokci, itp. Wychodząc na potrzeby pacjentów oferta i zakres usług są rozszerzane na bieżąco. Holistyczne podejście pozwala na wdrożenie działań prewencyjnych i długofalowe rozwiązanie problemów.";

export const contactData = {
  phone: "+48 574 616 004",
  email: "kontakt@dermativa.pl",
};

export const contactAddress = {
  text: "ul. Józefa Wybickiego 10, Bydgoszcz",
  link: "https://goo.gl/maps/dNKKyHyPoK6dLE3r5",
};

export const openingHours = {
  mon: "10:00 - 18:00",
  tue: "10:00 - 18:00",
  wen: "10:00 - 18:00",
  thu: "10:00 - 18:00",
  fri: "10:00 - 18:00",
  sat: "10:00 - 18:00",
  sun: "10:00 - 18:00",
};
